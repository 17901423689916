const moment = require("moment-timezone");

const more = {
  async wait(ms) {
    return new Promise((r) => setTimeout(r, ms));
  },
  fomatTimeStamp(timedelayRandom) {
    const timeunix = +new Date();
    const date = new Date(timeunix + timedelayRandom * 1000);
    const hours = date.getHours();
    const minutes = `0${date.getMinutes()}`;
    const seconds = `0${date.getSeconds()}`;
    const formattedTime = `${hours}:${minutes.substr(-2)}:${seconds.substr(-2)}`;
    return formattedTime;
  },
  toDateTimeZone(
    time = moment(),
    fromFormat = "YYYY/MM/DD HH:mm:ss ZZ",
    toFomart = "DD-MM-YYYY",
    zone = "Asia/Ho_Chi_Minh"
  ) {
    return moment(time, fromFormat).tz(zone).format(toFomart);
  },
  toTimeZone(time = moment(), zone = "Asia/Ho_Chi_Minh") {
    const format = "YYYY/MM/DD HH:mm:ss ZZ";
    return moment(time, format).tz(zone).format(format);
  },
  toTimeZone2(time = moment(), zone = "Asia/Ho_Chi_Minh") {
    const format = "YYYY/MM/DD HH:mm:ss";
    return moment(time, format).tz(zone).format(format);
  },
  getTimeHourNow(time = moment(), zone = "Asia/Ho_Chi_Minh") {
    const format = "HH";
    return moment(time, format).tz(zone).format(format);
  },
  getTimeStampMilisecond() {
    return moment().valueOf();
  },
  getTimeDelayStampMilisecond(timeStart) {
    const delay = (moment().valueOf() - timeStart) / 1000;
    return this.roundNumber(delay, 3);
  },
  roundNumber(value, decimals) {
    return Number(`${Math.round(`${value}e${decimals}`)}e-${decimals}`);
  },
  toTimestamp(time, format) {
    return moment(time, format).unix();
  },
  toTimestamp(time, format) {
    return moment(time, format).unix();
  },
  getTimeNowStamp(time = null, zone = "Asia/Ho_Chi_Minh") {
    if (time) {
      return moment(time).tz(zone).unix();
    }
    return moment().tz(zone).unix();
  },
  getTimeNowFromTimeStamp(timeStamp, format = null, zone = "Asia/Ho_Chi_Minh") {
    if (format) {
      return moment(moment.unix(timeStamp), format).tz(zone).format(format);
    }
    return this.toTimeZone(moment.unix(timeStamp));
  },
  replaceAll(target, search, replacement) {
    return target.replace(new RegExp(search, "g"), replacement);
  },
  removeUnicode(str) {
    str = str.replace(/à|á|ạ|ả|ã|â|ầ|ấ|ậ|ẩ|ẫ|ă|ằ|ắ|ặ|ẳ|ẵ/g, "a");
    str = str.replace(/è|é|ẹ|ẻ|ẽ|ê|ề|ế|ệ|ể|ễ/g, "e");
    str = str.replace(/ì|í|ị|ỉ|ĩ/g, "i");
    str = str.replace(/ò|ó|ọ|ỏ|õ|ô|ồ|ố|ộ|ổ|ỗ|ơ|ờ|ớ|ợ|ở|ỡ/g, "o");
    str = str.replace(/ù|ú|ụ|ủ|ũ|ư|ừ|ứ|ự|ử|ữ/g, "u");
    str = str.replace(/ỳ|ý|ỵ|ỷ|ỹ/g, "y");
    str = str.replace(/đ/g, "d");
    str = str.replace(/À|Á|Ạ|Ả|Ã|Â|Ầ|Ấ|Ậ|Ẩ|Ẫ|Ă|Ằ|Ắ|Ặ|Ẳ|Ẵ/g, "A");
    str = str.replace(/È|É|Ẹ|Ẻ|Ẽ|Ê|Ề|Ế|Ệ|Ể|Ễ/g, "E");
    str = str.replace(/Ì|Í|Ị|Ỉ|Ĩ/g, "I");
    str = str.replace(/Ò|Ó|Ọ|Ỏ|Õ|Ô|Ồ|Ố|Ộ|Ổ|Ỗ|Ơ|Ờ|Ớ|Ợ|Ở|Ỡ/g, "O");
    str = str.replace(/Ù|Ú|Ụ|Ủ|Ũ|Ư|Ừ|Ứ|Ự|Ử|Ữ/g, "U");
    str = str.replace(/Ỳ|Ý|Ỵ|Ỷ|Ỹ/g, "Y");
    str = str.replace(/Đ/g, "D");
    return str;
  },
  decode_base64(text) {
    const b = Buffer.from(text, "base64");
    return b.toString();
  },
  encode_base64(text) {
    const b = Buffer.from(text);
    return b.toString("base64");
  },
  formatNumber(x) {
    return x
      ? Number(x)
          .toString()
          .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
      : x;
  },
};

module.exports = more;
