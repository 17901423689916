<template>
  <div class="row">
    <div class="col-12">
      <div class="card p-4 flex-column flex-md-row">
        <slot name="user-total"></slot>
        <h6 class="mb-0">
          Số lượng user đang online:
          <span class="badge bg-success font-size-14">{{ amount | formatNumber }}</span>
        </h6>
      </div>
    </div>
  </div>
</template>

<script>
import userApi from "@/api/socket/user";

export default {
  name: "UserOnline",
  data() {
    return {
      amount: 0,
      timer: null,
    };
  },
  computed: {},
  created() {
    this.counterUserOnline();
    this.timer = setInterval(this.counterUserOnline, 5000);
  },
  beforeDestroy() {
    clearInterval(this.timer);
  },
  methods: {
    async counterUserOnline() {
      const response = await userApi.counterOnline();
      if (response.data) {
        this.amount = response.data;
      } else {
        this.amount = 0;
      }
    },
  },
};
</script>

<style lang="scss" scoped></style>
