import { requestApi } from "./index";

const job = {
  list: async (params = "") => requestApi("/admin2021/facebook/jobs" + params),
  update: async (params = {}) => requestApi("/admin2021/facebook/jobs/update", params),
  delete: async (params = {}) => requestApi("/admin2021/facebook/jobs/delete", params),
  priority: async (params = {}) => requestApi("/admin2021/facebook/jobs/priority", params),
  instagram: {
    list: async (params = "") => requestApi("/admin2021/instagram/jobs" + params),
    update: async (params = {}) => requestApi("/admin2021/instagram/jobs/update", params),
    delete: async (params = {}) => requestApi("/admin2021/instagram/jobs/delete", params),
    priority: async (params = {}) => requestApi("/admin2021/instagram/jobs/priority", params),
  },
};

export default job;
